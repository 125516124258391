.filter {
  .filter-tags {
    margin-top: 25px;
    margin-left: 10px;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    .ant-tag {
      @include pillRadius;
      font-size: 12px;
      margin-left: 10px;
      margin-right: 0;
      padding: 4px 15px;
      background-color: $lightPurple;
      color: $charcol;
      border: 1px solid #b4a1f2;
      .anticon {
        font-size: 10px;
        margin-left: 10px;
      }
    }
  }
}

.pool {
  .user-content {
    .ant-table-wrapper {
      .ant-table-thead {
        tr {
          th {
            width: auto;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            &:first-child {
              font-weight: 600;
              cursor: pointer;
            }
          }
        }
      }
      .table-action {
        .ant-btn {
          height: 35px;
          width: 40px;
          line-height: 0px;
          padding: 0;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.overview {
  .pool-table {
    table {
      padding-bottom: 200px;
    }
    .user-flag {
      display: flex;
      align-items: center;
      .flag {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    .ant-table-tbody {
      .ant-table-row td {
        .user-info {
          .ant-card-body {
            .ant-card-meta-title {
              max-width: 100px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .user-name {
              max-width: 100px;
              overflow: hidden;
              display: inline-block;
              white-space: nowrap;
              vertical-align: middle;
              text-overflow: ellipsis;
            }
          }
        }
        .ant-btn-text {
          padding: 0;
          margin-left: 5px;
          opacity: 0;
          visibility: 0;
        }
        .ant-btn-link {
          width: 100%;
          text-align: left;
          height: auto;
          .category-box {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
          }
        }
        .category-tag {
          margin-bottom: 2px;
          margin-top: 2px;
        }
      }
    }
  }
  .ant-table-wrapper {
    .ant-table-thead {
      tr {
        th {
          width: auto;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-row {
        td {
          font-size: 12px;
          color: #7f7f7f;
          .ant-btn-link {
            color: $purple;
            padding: 0;
            font-size: 12px;
            &:hover {
              .ant-btn-text {
                opacity: 1;
                visibility: visible;
              }
            }
          }
          .ant-progress-line {
            width: 130px;
          }
          .user-info {
            .ant-card-body {
              .user-img {
                position: relative;
              }
              .pro-pic {
                height: 46px;
                width: 46px;
                border-radius: 50%;
                border: 1px solid $purple;
              }
              .flag {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 15px;
                width: 15px;
                border-radius: 50%;
              }
              .ant-card-meta-title {
                margin-bottom: 0;
              }
              .ant-card-meta-description {
                font-size: 12px;
                color: $purple;
                img {
                  height: 16px;
                  width: 16px;
                  margin-right: 3px;
                }
              }
            }
          }
          .registered,
          .unregistered {
            @include pillRadius;
            font-size: 12px;
            padding: 0px 10px;
          }
          .registered {
            background: rgb(119, 74, 243);
            background: linear-gradient(
              90deg,
              rgba(119, 74, 243, 1) 45%,
              rgba(84, 52, 174, 1) 100%
            );
            color: #fff;
          }
          .unregistered {
            background-color: #dddddd;
            color: #7b7b7b;
          }
          .danger-button {
            width: 40px;
            height: 35px;
            padding: 0;
          }
        }
        .category-cell {
          .des-dropdown {
            width: 450px;
          }
        }
      }
    }
  }
}

.profile-card-button {
  .ant-btn {
    margin-left: 10px;
    padding: 8px 20px;
    svg {
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}
.add-influencer-popup {
  .ant-modal-header {
    border-bottom: none;
  }
  .table-header {
    margin-top: 0;
    padding-top: 0;
    .ant-input-affix-wrapper {
      padding: 0;
      .ant-btn {
        padding: 0px;
        height: 35px;
        width: 40px;
      }
    }
    .search-dropdown {
      .ant-input-prefix {
        .ant-btn {
          padding: 7px 0px;
          width: 40px;
        }
      }
    }
  }

  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
  }
  .ant-table-tbody {
    tr {
      td {
        text-align: right;
      }
    }
  }
  .registered,
  .unregistered {
    @include pillRadius;
    font-size: 12px;
    padding: 0px 10px;
  }
  .registered {
    background: rgb(119, 74, 243);
    background: linear-gradient(
      90deg,
      rgba(119, 74, 243, 1) 45%,
      rgba(84, 52, 174, 1) 100%
    );
    color: #fff;
  }
  .ant-modal-footer {
    border-top: none;
    padding-bottom: 30px;
    .purple-button {
      width: 118px;
    }
  }
  .user-content {
    .ant-table-wrapper {
      .ant-table-tbody {
        .ant-table-row td {
          .user-info {
            .ant-card-body {
              .pro-pic {
                height: 35px;
                width: 35px;
                border: 1px solid $purple;
              }
            }
          }
        }
      }
    }
  }
}
.edit-details {
  form {
    nz-form-item {
      margin-bottom: 15px;
    }
  }
  .edit-card {
    margin-top: 10px;
    margin-bottom: 30px;
    border: none;

    .ant-card-body {
      padding: 0;
    }
    .ant-card-meta {
      display: flex;
      align-items: center;
    }
    .ant-card-meta-avatar {
      img {
        height: 64px;
        width: 64px;
      }
    }
  }
  .purple-button {
    padding: 8px 30px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-modal-footer {
    border-top: none;
    padding-bottom: 30px;
  }
}

.overview {
  .table-header {
    .filter {
      .trans-button {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
    .pool-table-container {
      height: 0;
      overflow: hidden;
    }
    .showResult {
      margin-bottom: 20px;
      height: auto;
      .ant-table-wrapper {
        margin-bottom: 15px;
      }
    }
  }
}
.pool-logs {
  margin-left: 0;
  padding-left: 0;
  .overview {
    width: 100%;
  }
  .logs-list {
    li {
      list-style-type: none;
      position: relative;
      border-left: 1px solid #daccff;
      padding-left: 20px;
      &::before {
        content: "";
        position: absolute;
        height: 12px;
        width: 12px;
        background-color: #fff;
        border: 1px solid #daccff;
        border-radius: 50%;
        left: 0;
        margin-left: -7px;
      }
      .list-header {
        .title {
          span {
            font-weight: 600;
            color: $purple;
            cursor: pointer;
          }
        }
        .date {
          font-size: 12px;
          color: #969696;
          p {
            margin-right: 20px;
            span {
              color: $purple;
            }
          }
        }
      }
      .ant-table-tbody {
        tr {
          td {
            border-bottom: none;
            background-color: #f8f8f8;
            padding: 10px 16px;
          }
        }
      }
      .ant-table-placeholder {
        display: none;
      }
    }
    .date {
      font-size: 12px;
      color: #969696;
      p {
        margin-right: 20px;
        span {
          color: $purple;
        }
      }
    }
  }
  .ant-table-tbody {
    tr {
      td {
        border-bottom: none;
        background-color: #f8f8f8;
      }
    }
  }
  .ant-table-placeholder {
    display: none;
  }
}
.deleted-influencer {
  .ant-table-tbody tr td {
    background-color: #fdeded;
  }
}
.pool {
  .ant-table-tbody {
    tr {
      td {
        .ant-card-meta-title {
          margin-bottom: 0;
        }
      }
    }
  }
}
.des-dropdown {
  position: absolute;
  top: 60px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 5px 16px #00000014;
  background-color: #fff;
  width: 385px;
  z-index: 99;
  right: 0;

  textarea {
    resize: none;
    border-radius: 9px;
    border: 1px solid #e5e5e5;
    font-size: 12px;
    color: #111;
    padding: 7px 12px;
    width: 100%;
    min-height: 80px;

    &:focus {
      border-color: #6631f7;
    }
  }
  .ant-select {
    width: 100%;
  }
  .btns {
    text-align: end;
    margin-top: 30px;

    .ant-btn {
      padding: 11px 23px;
      font-size: 14px;
      height: auto;
    }

    .btn-cancel {
      color: #6e3afb;
      border: 1px solid #6e3afb;
      border-radius: 5px;
      background-color: #fff;
    }

    .btn-save {
      @extend .btn-cancel;
      color: #fff;
      background-color: #6e3afb;
      margin-left: 5px;
    }
  }
}
