.user-content {
  .ant-table-wrapper {
    .ant-table-thead {
      tr {
        th {
          background-color: #f0eafc;
          font-size: 13px;
          font-weight: 600;
          text-transform: capitalize;
          width: 20%;

          .sorting-btn {
            width: 30px;
            padding: 2px;
          }
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        td {
          .category-tag {
            @include pillRadius;
            background-color: #eee8ff;
            color: $purple;
            border: none;
            padding: 2px 10px;
            margin-bottom: 5px;

            &:last-child {
              margin-right: 0;
            }
          }

          .user-info {
            border: none;
            background-color: transparent;
            cursor: pointer;

            .ant-card-body {
              padding: 0;
              display: flex;
              align-items: center;

              .ant-card-meta-title {
                font-size: 14px;
                font-weight: 500;
                max-width: 170px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              img {
                margin-right: 10px;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                object-fit: scale-down;
              }
            }
          }

          .user-email {
            display: flex;
            flex-wrap: wrap;

            span {
              flex: 70%;
              max-width: 70%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .ant-space {
            .ant-btn {
              margin-left: 10px;
              height: 32px;
              width: 32px;
              padding: 6px;
            }
          }
        }

        &:nth-child(even) {
          background-color: #fafafa;
        }

        .copy-btn {
          margin-left: 30px;
          vertical-align: middle;
          padding: 0;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.table-header {
  background-color: #fff;
  margin-top: 20px;
  padding: 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .result,
  .title {
    font-weight: 600;
  }

  .ant-typography {
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 0;
    font-weight: 500;
  }

  .ant-btn {
    padding: 7px 15px;
    height: auto;
  }
}

.table-header + .ant-card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.detail-content {
  .profile-card {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .ant-card-body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 20px;
      justify-content: space-between;

      &::before,
      &::after {
        display: none;
      }

      .inner-body {
        display: flex;
        align-items: center;

        img {
          height: 64px;
          width: 64px;
          border-radius: 50%;
        }
      }

      .pro-img {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        border: 1px solid #6631f7;
      }

      .ant-card-meta {
        margin-left: 15px;

        .ant-card-meta-detail {
          .ant-card-meta-title {
            font-size: 18px;
            color: #4d4d4d;
            margin-bottom: 0;

            .purple-tag {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .detail-tabs {
    .ant-tabs-nav {
      background-color: #fff;
      padding: 0px 20px;

      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          text-transform: capitalize;
          color: #808080;

          a {
            color: #808080;
          }
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #6631f7;

          a {
            color: #6631f7;
          }
        }
      }

      .ant-tabs-ink-bar {
        background-color: #6631f7;
      }
    }
  }
}

.user-detail {
  min-height: 460px;

  .card-header {
    display: flex;
    justify-content: space-between;

    .ant-btn-text {
      color: #6631f7;

      svg {
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    .user-img {
      height: 64px;
      width: 65px;
      border-radius: 50%;
    }
  }

  .ant-list {
    margin-bottom: 20px;

    .ant-list-item {
      border-bottom: none;
      padding-bottom: 0;

      span {
        color: #666666;
      }
    }
  }

  .otp-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f0eafc;
    padding: 15px;
    border-radius: 8px;

    .otp-time {
      .ant-typography {
        margin: 0;
      }

      h5.ant-typography {
        font-size: 12px;

        a {
          color: #7546f8;
        }
      }
    }

    .otp-number {
      text-align: right;

      ul {
        margin: 0;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        margin-bottom: 5px;

        li {
          list-style-type: none;
          height: 32px;
          width: 32px;
          border: 1px solid #f0eafc;
          color: #7546f8;
          margin-right: 2px;
          padding: 5px 12px;
          border-radius: 8px;
        }
      }

      .ant-btn {
        color: #6631f7;
      }
    }
  }

  .card-footer {
    text-align: center;
    margin-top: 15px;
    color: #4d4d4d;

    .light-purple {
      width: 255px;
      height: 44px;
      font-weight: 500;
    }

    .light-red {
      width: 255px;
      height: 44px;
      font-weight: 500;
      background-color: #ffffff;
      color: #ff6b6b;
    }

    p {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}

.plan-card {
  min-height: 460px;

  .ant-card-body {
    .card-header {
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 30px;

      .card-detail {
        h2 {
          color: #7546f8;
          margin-top: 0;
          margin-bottom: 0;
          font-weight: 700;
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        p {
          color: #666666;
          font-size: 12px;

          span {
            color: #333;
          }
        }

        .title {
          font-weight: 600;
        }
      }

      .sub-button {
        .ant-btn-text {
          color: #ed6666;
        }

        .ant-btn {
          padding: 12px 24px;
          height: auto;
          margin-left: 5px;
        }
      }
    }

    .tool-list {
      min-height: 240px;

      .ant-list-item {
        position: relative;
        padding-left: 30px;
        padding-top: 0px;
        border-bottom: 0;
        flex-wrap: wrap;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 20px;
          width: 20px;
          background-image: url("../../images/tick.png");
        }

        .tool-progress {
          flex: auto;
          padding-right: 20px;

          .header {
            display: flex;
            justify-content: space-between;

            h3 {
              margin: 0;
            }

            p {
              margin: 0;
              font-weight: 500;
            }

            div {
              &.ant-typography {
                span {
                  color: #7546f8;
                }
              }
            }
          }
        }

        .inner-tool-progress {
          width: 100%;
          display: flex;
          margin: 10px 0px;

          nz-progress {
            width: 100%;
            padding: 0px 10px;
          }

          .tool-progress {
            flex: auto;
            padding-right: 20px;

            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;

              img {
                height: 16px;
                width: 16px;
              }

              p {
                margin: 0;
                font-weight: 500;

                span {
                  color: #7546f8;
                }
              }
            }
          }
        }

        .ant-tag {
          border: none;
          background-color: #f0eafc;
          border-radius: 30px;
          color: #7546f8;
          margin-right: 0;
        }
      }
    }

    .footer {
      .trans-button {
        width: 112px;
        height: 44px;
      }
    }
  }
}

.plan-component {
  max-width: 1260px;

  .ant-modal-header {
    border-bottom: none;
    padding: 30px 30px 0px;

    .ant-modal-title {
      color: #1a1a1a;
      font-weight: normal;

      h4 {
        font-size: 22px;
        margin-bottom: 8px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .ant-modal-body {
    padding: 15px 30px 25px 30px;
  }

  .package {
    .package-header {
      margin-bottom: 10px;
    }

    .package-option {
      width: 100%;

      .ant-radio-wrapper {
        width: 100%;
        border: 1px solid #e0d6fd;
        border-radius: 16px;
        min-height: 130px;
        overflow: hidden;
        align-items: center;
        margin-right: 0;
        padding: 0px 0px 0px 30px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0px;
        }

        .ant-radio + {
          span {
            flex: auto;
            padding: 0;
          }
        }

        .ant-radio {
          opacity: 0;
          position: absolute;
          right: 30px;
          top: 30px;

          .ant-radio-inner {
            border-color: #6631f7;
            height: 24px;
            width: 24px;
          }
        }

        .ant-card {
          border: none;
          background-color: transparent;

          .ant-card-body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 125px;
            padding-top: 30px;
            flex-wrap: wrap;

            &::before,
            &::after {
              display: none;
            }

            .ant-card-meta {
              .ant-card-meta-detail {
                .ant-card-meta-title {
                  color: #666666;
                  font-size: 18px;
                  margin-bottom: 0;
                  max-width: 300px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .ant-card-meta-description {
                  color: #1a1a1a;
                  font-size: 30px;
                  font-weight: 700;
                }
              }
            }

            .package-list {
              padding-left: 0px;
              padding-top: 15px;
              border-top: 1px solid #e0d6fd;
              margin: 0;
              width: 100%;
              display: none;
              margin-top: 20px;

              li {
                list-style-type: none;
                position: relative;
                margin-bottom: 15px;
                padding-left: 25px;

                img {
                  height: 18px;
                  width: 18px;
                  border-radius: 50px;
                  margin-right: 5px;
                  vertical-align: baseline;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                &::before {
                  content: "";
                  background-image: url("../../images/tick-box.png");
                  position: absolute;
                  left: 0;
                  top: 2px;
                  height: 16px;
                  width: 16px;
                }
              }
            }
          }
        }

        &:hover {
          border-color: #6631f7;
          background-color: #f0eafc;

          .ant-radio {
            opacity: 1;
          }
        }
      }

      .ant-radio-wrapper.active {
        border-color: #6631f7;
        background-color: #f0eafc;

        .ant-radio {
          opacity: 1;
        }
      }

      .ant-radio-wrapper-checked {
        border-color: #6631f7;
        background-color: #f0eafc;

        .ant-radio {
          opacity: 1;

          .ant-radio-inner {
            background-color: #6631f7;

            &::after {
              background-color: transparent;
              background-image: url("../../images/check.png");
              transform: scale(0.9);
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }

        .package-list {
          display: block !important;
        }
      }
    }

    .payment-form {
      padding: 20px 20px;
      border: 2px solid #e0d6fd;
      border-radius: 16px;
      min-height: 100%;

      .ant-page-header {
        padding: 0;

        .ant-page-header-heading-title {
          font-size: 18px;
          font-weight: 500;
        }
      }

      .ant-radio-group {
        text-align: center;
        display: flex;

        .ant-radio-button-wrapper {
          height: auto;
          flex: auto;
          margin-right: 15px;
          border-radius: 8px;
          min-height: 100px;
          max-width: 200px;
          padding: 20px 15px 10px;
          color: #855af9;
          border: 1px solid #e6e6e6;
          box-shadow: none !important;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            border-color: #855af9;
            background-color: #f0eafc;
          }
        }

        .ant-radio-button-wrapper-checked {
          border-color: #855af9;
          background-color: #f0eafc;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          display: none;
        }
      }

      .ant-form {
        .ant-form-item {
          margin-bottom: 17px;

          .ant-form-item-label {
            label {
              font-size: 16px;
              color: #000;
            }
          }
        }

        .ant-input {
          font-size: 16px;
          border: 2px solid #e6e6e6;
          height: 48px;
        }

        .ant-select {
          font-size: 16px;

          .ant-select-selection-placeholder {
            line-height: 46px !important;
          }

          .ant-select-selector {
            border: 2px solid #e6e6e6;
            height: 48px;

            &:focus {
              border-color: #6631f7 !important;
            }
          }
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(
            .ant-select-customize-input
          )
          .ant-select-selector {
          border-color: #6631f7;
        }

        .ant-picker {
          width: 100%;
          font-size: 16px;
          border: 2px solid #e6e6e6;
          height: 48px;

          &:hover,
          &:focus {
            border-color: #6631f7;
          }

          .ant-picker-clear {
            right: 30px;
          }

          input {
            font-size: 16px;
          }
        }

        .ant-picker-focused {
          border-color: #6631f7;
        }
      }
    }

    .packge-button {
      margin-top: 20px;

      p {
        margin-bottom: 0;
        font-size: 16px;
        text-transform: capitalize;
        color: #4d4d4d;

        span {
          font-size: 24px;
          font-weight: 700;
          margin-left: 10px;
        }
      }

      .gradient-button {
        margin-left: 50px;
        font-size: 16px;
        text-transform: capitalize;
        height: auto;
        padding: 10px 65px;
        background: rgb(102, 49, 247);
        color: #fff;
        border: none;
        background: linear-gradient(
          0deg,
          rgba(102, 49, 247, 1) 0%,
          rgba(133, 90, 249, 1) 100%
        );

        &:hover {
          background: linear-gradient(
            0deg,
            rgba(133, 90, 249, 1) 0% rgba(102, 49, 247, 1) 100%
          );
        }
      }
    }

    .plan-range {
      border: 2px solid #e0d6fd;
      border-radius: 16px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0px 20px;

      .ant-page-header {
        padding-left: 0;
        padding-right: 0;

        .ant-page-header-heading {
          border-bottom: 1px solid #e6e6e6;
        }

        .ant-page-header-heading-title {
          color: #6631f7;
          font-size: 18px;
        }
      }

      .range-list {
        padding: 0px;
        margin: 0;

        li {
          list-style-type: none;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 15px;

          .range-slider,
          .plan-name {
            display: flex;
            flex-wrap: wrap;
            flex: 50%;
            justify-content: space-between;
          }

          .plan-name {
            align-items: center;

            .number {
              height: 40px;
              width: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #333333;
              border-radius: 4px;
              background-color: #e6e6e6;
            }
          }

          .range-slider {
            padding-left: 5px;

            span {
              display: flex;
              flex: auto;
              align-items: center;

              nz-slider {
                flex: auto;

                .ant-slider {
                  margin: 10px 10px 10px;
                }

                .ant-slider-rail {
                  background-color: #e0d6fd;
                }

                .ant-slider-track {
                  background-color: #6631f7;
                }

                .ant-slider-handle {
                  height: 17px;
                  width: 17px;
                  background-color: #6631f7;
                  border: none;
                  margin-top: -7px;
                }
              }
            }

            .ant-input-number {
              height: 44px;
              width: 44px;
              border-radius: 4px;
              padding-left: 2px;

              .ant-input-number-handler-wrap {
                display: none;
              }

              .ant-input-number-input-wrap {
                .ant-input-number-input {
                  padding: 0;
                  height: 44px;
                  text-align: center;
                }
              }
            }
          }
        }
      }

      .footer {
        padding-top: 15px;
        border-top: 1px solid #e6e6e6;

        label {
          font-size: 16px;
          font-weight: 500;
        }

        .ant-input-affix-wrapper {
          color: #6631f7;
          font-size: 22px;
        }

        .ant-input {
          color: #6631f7;
          font-weight: 500;
          font-size: 22px;
          height: 56px;
        }
      }
    }
  }
}

.filter {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;

  .ant-input-wrapper {
    .ant-input-group-addon {
      .ant-btn {
        background-color: #6631f7;
        border: 1px solid #6631f7;
        line-height: 50px;
        border-radius: 0 8px 8px 0 !important;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .ant-select {
    width: 100%;
    text-transform: capitalize;

    .ant-select-selector {
      border-radius: 8px;
    }

    .ant-select-selection-placeholder {
      line-height: 40px;
    }

    .ant-select-selector {
      color: #b3b3b3;

      .ant-select-selection-search-input {
        height: 41px;
      }

      .ant-select-selection-item {
        img {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }

  .ant-input {
    // color: #b3b3b3;
  }
}

.pricing-buttons {
  .ant-radio-wrapper {
    border: 1px solid #f2edff;
    height: 36px;
    width: 150px;
    border-radius: 8px;
    color: #777777;
    justify-content: center;
    padding: 7px 0px;

    span {
      font-size: 14px;
      font-weight: 500;
      padding-right: 0;
    }
  }

  .ant-radio-wrapper-checked {
    background-color: #f2edff;
    color: #6631f7;
    border-color: #6631f7;
  }
}

.downgrade-placeholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-result-subtitle {
    font-size: 20px;
    color: $charcol;
    max-width: 395px;
  }
}

.user-table {
  .ant-tag {
    padding: 5px 15px;
    font-weight: 600;
  }
}

.social {
  padding: 20px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 1px 4px #0b14260a;
  margin-bottom: 15px;

  .list {
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    .add {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: center;
      .ant-btn {
        border-radius: 6px;
        padding: 2px 10px;
        font-size: 12px;
        height: 25px;
        margin-left: 5px;
      }
    }
  }

  .new {
    margin-top: 15px;

    nz-mention.ant-mentions {
      border: 0px;
    }

    .ant-mentions:focus,
    .ant-mentions:active,
    .ant-mentions-focused {
      border: 0px;
      box-shadow: none;
      border-right-width: 0px;
      outline: 0;
    }
  }

  &:hover {
    border-color: #6631f7;
  }
}

// img-name
.img-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  .media {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    img {
      width: 50px;
      height: 50px;
      max-width: 50px;
    }

    .sub {
      border-radius: 50%;
      box-shadow: 0 0 0 2px #fff;
      background-color: #718096;
      color: #fff;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      z-index: 4;
      bottom: 0;
      right: 0;
      min-width: 12px;
      height: 20px;
      width: 20px;
      line-height: 12px;
      font-size: 7.5px;
      padding: 1px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .body {
    h4 {
      margin-bottom: 3px;
    }

    p {
      margin-bottom: 0px;
    }
  }
}

.user-tile {
  img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }

  h5 {
    font-size: 18px;
    margin-top: 10px;
    text-transform: capitalize;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 10px;
    font-family: PrimaryFont600;
  }

  ul {
    padding-left: 0;

    li {
      list-style-type: none;
      margin-bottom: 10px;
      color: #777777;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      img {
        margin-right: 10px;
        height: 16px;
        width: 16px;
        border-radius: 0;
      }
    }
  }
}
