// ---- Stats ---------
nz-select {
  width: 100%;
}
.main-stats {
  margin-bottom: 50px;

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    /* border-color: #8d5cff; */
    box-shadow: 0 0 0 0 rgba(102, 49, 247, 0.2);
    border-right-width: 0px;
    outline: 0;
  }

  .no-data {
    img {
      width: 90px !important;
      padding-top: 20px !important;
    }
    h4 {
      font-size: 14px !important;
    }
  }

  .results {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    height: 43px;

    .ant-tag {
      padding-right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      border-radius: 10px;

      .ant-tag-close-icon {
        margin-right: -3px;
        padding: 4px;
      }
    }

    .ant-tag.reset {
      background-color: #6631f7;
      color: #fff;
      border-color: #6631f7;
      cursor: pointer;

      &:hover {
        color: #fff;
        border-color: #8d5cff;
        background: #8d5cff;
      }
    }

    .dash {
      width: 1px;
      height: 100%;
      background-color: #d6d6d6;
      margin-right: 15px;
      margin-left: 5px;
    }
  }

  .top-users {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    border-bottom: 1px #e3e3e3 solid;
    padding-bottom: 12px;
    margin-bottom: 12px;

    li {
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      i {
        font-size: 20px;
        color: #a9a9a9;
        margin-right: 10px;
      }
    }

    li.user {
      img {
        width: 22px;
        margin-right: 10px;
        border-radius: 60px;
      }

      h4 {
        color: #6631f7;
        font-size: 14px;
      }
    }

    li.info {
      justify-content: flex-start;
      width: 105px;

      img {
        width: 20px;
        margin-right: 10px;
      }

      h4 {
        color: #555;
        font-size: 14px;
        font-weight: 600;
      }
    }

    &:last-child {
      border-bottom: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .tab-title {
      margin: 0px;

      h3 {
        font-family: "PrimaryFont500";
        font-size: 16px;
        color: #111;
        margin-bottom: 10px;

        a {
          i {
            color: #888;
            font-size: 16px;
            margin-left: 10px;
            position: relative;
            top: -3px;
          }
        }
      }
    }
  }

  .info-card {
    margin-bottom: 15px;

    nz-card.bg {
      .ant-card-body {
        padding: 12px;

        .ant-select {
          width: 100%;
        }
      }
    }
  }

  .tab-title {
    margin-bottom: 10px;
    margin-top: 10px;

    h3 {
      font-family: "PrimaryFont500";
      font-size: 16px;
      color: #444;

      a {
        i {
          color: #888;
          font-size: 16px;
          margin-left: 10px;
        }
      }
    }
  }

  .card-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 3px #00000012;
    padding: 15px;
    margin-bottom: 12px;

    .box {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      min-height: 56px;

      .info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;

        .icon {
          width: 50px;
          text-align: center;
          margin-right: 5px;

          img {
            margin-right: 10px;
          }

          i {
            font-size: 24px;
            color: #262626;
          }
        }

        .value {
          width: 100%;

          p {
            color: #444;
            font-size: 14px;
            margin-bottom: 6px;
            font-family: "PrimaryFont400";
          }

          h2 {
            font-size: 20px;
            color: #6631f7;
            margin: 0px;
            font-family: "PrimaryFont600";
          }
        }
      }

      .others {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;

        .add {
          width: auto;
          text-align: right;

          a {
            display: inline-block;
            background-color: #f0e9fe;
            padding: 8px 10px;
            border-radius: 6px;
            text-align: center;
            min-width: 48px;
            margin-left: 5px;
            text-decoration: none;

            i {
              font-size: 18px;
              color: #6631f7;
              margin-bottom: 5px;
            }

            img {
              width: 18px;
              margin-bottom: 6px;
            }

            h4 {
              font-size: 14px;
              font-family: "PrimaryFont600";
              color: #6631f7;
              margin-bottom: 0px;
            }
          }

          a.green {
            background-color: rgba(72, 178, 104, 0.144);

            h4 {
              color: #444;
            }

            i {
              color: #48b268;
            }
          }

          a.yellow {
            background-color: rgba(246, 164, 0, 0.144);

            h4 {
              color: #444;
            }

            i {
              color: #f6a500;
            }
          }

          a.red {
            background-color: rgba(253, 71, 74, 0.15);

            h4 {
              color: #444;
            }

            i {
              color: #fd474a;
            }
          }
        }
      }
    }
  }

  .chart-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 3px #00000012;
    padding: 15px;
    margin-bottom: 12px;
  }
}

// transactions
.transactions {
  .filter {
    margin-bottom: 15px;

    .results {
      justify-content: flex-start;
      margin-top: 10px;
      .ant-tag {
        background-color: #f0ebfd;
        color: #6631f7;
        border-color: #f0ecfe;
        span {
          color: #6631f7;
        }
      }
    }
  }
  .info-card {
    margin-top: 20px;
    .upload-export {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: flex-start;
      gap: 30px;
      nz-upload {
        border-left: 1px #d9d9d9 solid;
        padding-left: 30px;
      }
      .ant-upload-list-text .ant-upload-list-item-name {
        max-width: 95px !important;
        font-size: 13px;
      }
    }
  }
  .user-content {
    .ant-table-wrapper .ant-table-thead tr th {
      width: auto;
    }
  }
  .info-card nz-card.bg .ant-card-body .ant-select {
    width: 130px;
  }

  .card-box {
    padding: 10px 15px;
    .box .others {
      .add {
        a {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: flex-start;
          align-items: center;
          padding: 12px 10px;
          i {
            font-size: 20px;
          }
          span {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: flex-start;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 10px;
            h4 {
              font-size: 20px;
            }
            p {
              font-size: 12px;
              color: #7a7977;
              margin-top: 5px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

// creator-profiles
.creator-profiles {
  .results {
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    height: initial;
    .ant-tag {
      background-color: #f0ebfd;
      color: #6631f7;
      border-color: #f0ecfe;
      span {
        color: #6631f7;
      }
    }

    .ant-tag {
      padding-right: 10px;
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      border-radius: 10px;

      .ant-tag-close-icon {
        margin-right: -3px;
        padding: 4px;
      }
    }

    .ant-tag.reset {
      background-color: #6631f7;
      color: #fff;
      border-color: #6631f7;
      cursor: pointer;

      &:hover {
        color: #fff;
        border-color: #8d5cff;
        background: #8d5cff;
      }
    }

    .dash {
      width: 1px;
      height: 100%;
      background-color: #d6d6d6;
      margin-right: 15px;
      margin-left: 5px;
    }
  }
  .filter .ant-select .ant-select-selector .ant-select-selection-item img {
    height: 20px;
    margin-right: 5px;
  }
}

.merge-profiles-modal {
  .add-search {
    margin: 30px;
    margin-bottom: 0;

    nz-select {
      width: 100%;
    }
  }

  .ant-modal-body {
    padding: 0;
    background: #f0f2f5;
  }

  #card_container {
    perspective: 1000px;
    transform-style: preserve-3d;
    background-color: #f0f2f5;
    padding: 30px;
  }

  .wrapper {
    display: flex;
    gap: 50px;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;

    .user-box {
      border-radius: 15px;
      width: 280px;
      background-color: #fff;
      border: 1px solid #cfbff9;
      position: relative;

      .links {
        position: absolute;
        top: -12px;
        left: 0;
        right: 0;
        text-align: center;
        h4 {
          background-color: #cfbff9;
          padding: 1px 20px;
          border-radius: 6px;
          display: inline-block;
          font-size: 14px;
          margin: 0px;
        }
      }

      .default {
        display: flex;
        padding: 15px;
        // background-color: #e6e6e6;
        border-radius: 15px 15px 0 0;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;

        .avatar {
          text-align: center;
          img {
            width: 70px;
            height: 70px;
            border-radius: 100%;
          }
          h2 {
            margin-bottom: 0;
            font-size: 20px;
          }
        }
      }

      .options {
        padding: 10px;

        .top {
          border-bottom: 1px solid rgb(233, 233, 233);
          padding-bottom: 10px;
          margin-bottom: 15px;
          label {
            margin-bottom: 0px;
          }
        }
        label {
          margin-bottom: 6px;
          margin-left: 0px;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: flex-start;
          align-items: flex-start;
          span {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 18px;
              height: 18px;
              margin-right: 6px;
            }
            span.icon {
              margin-right: 9px;
              font-size: 15px;
              margin-left: 2px;
              color: #6631f7;
            }
          }
        }
        .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
          border-color: rgb(255 255 255 / 45%);
          animation-name: none;
        }
        .ant-checkbox-disabled + span {
          color: rgba(0, 0, 0, 0.25);
          cursor: not-allowed;
        }
        .ant-checkbox-disabled + span img {
          opacity: 0.45;
        }
      }
    }

    .destination {
      border: 1px solid #cfbff9;
    }

    .box_wrap {
      border-radius: 15px;
      width: 280px;
      height: 150px;
      overflow: hidden;
    }

    .bdr {
      width: 1px;
      background: transparent;
      position: absolute;
      left: -18%;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }

    .show {
      margin-top: 5px;
      .platform {
        padding-left: 22%;

        .df {
          justify-content: flex-start;
        }

        .name h2 {
          font-size: 18px;
          font-weight: 500;
        }

        .df {
          margin-top: 8px;

          img {
            width: 18px;
            height: 18px;
          }

          p {
            font-size: 14px;
            margin-left: 10px;
            position: relative;
            top: -1px;
          }
        }
      }
      .values {
        padding-left: 22%;
        padding-top: 12px;
        position: relative;
        z-index: 1;
        ul {
          li {
            display: flex;
            padding-bottom: 10px;

            &:last-child {
              padding-bottom: 0;
            }

            i {
              margin-right: 10px;
              margin-left: 2px;
              font-size: 16px;
              color: #6631f7;
            }
          }
        }
      }
    }
    .output {
      background: #fff;
      height: 470px;
      .default {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 35px 10px 0px;
        .avatar {
          margin-bottom: 10px;
        }
      }
      .box_wrap {
        .spacer {
          height: 300px;
          background: #f6f0ff;
          border-radius: 0 0 15px 15px;

          .block {
            border-radius: 100%;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
          }
        }
      }
      /* Default colors when no platform is selected */
      .box_wrap {
        .block:nth-child(1) {
          background: #6631f7; // Default color for block 1
        }

        .block:nth-child(2),
        .block:nth-child(3),
        .block:nth-child(4),
        .block:nth-child(5) {
          background: linear-gradient(
            0deg,
            #6631f7,
            #ece6fe 20%
          ); // Default gradient for other blocks
        }
      }

      &:before {
        content: "";
        width: 200px;
        height: 25px;
        bottom: 130px;
        z-index: -1;
        left: 75px;
        position: absolute;
        background: rgba(0, 0, 0, 1);
        box-shadow: 0 0 200px rgba(0, 0, 0, 1), 0 0 100px rgba(0, 0, 0, 1),
          0 0 100px rgba(0, 0, 0, 1);
      }

      .box_wrap {
        position: absolute;
        bottom: 0;
        left: 0;

        .spacer {
          left: 0;
          bottom: 0px;
          position: absolute;
          overflow: hidden;
          height: 300px;
          width: 278px;
          background: #f6f0ff;
          border-radius: 0px 0px 15px 15px;

          .block {
            position: absolute;
            border-radius: 100%;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
          }

          .block:nth-child(1) {
            left: -100px;
            top: 150px;
            width: 500px;
            height: 100px;
            filter: blur(50px);
          }

          .block:nth-child(2) {
            right: -170px;
            top: -250px;
            width: 500px;
            height: 500px;
          }

          .block:nth-child(3) {
            left: -120px;
            top: -260px;
            width: 600px;
            height: 500px;
          }

          .block:nth-child(4) {
            left: -250px;
            top: -420px;
            width: 700px;
            height: 700px;
          }

          .block:nth-child(5) {
            left: -100px;
            top: -250px;
            width: 500px;
            height: 500px;
          }

          .block:nth-child(6) {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
            background: #fff;
            width: 450px;
            height: 450px;
            top: -206px;
            left: -50px;
          }
        }
      }
    }
  }
}

.search-result-dropdown {
  .ant-list-item {
    padding: 5px 0px;
  }
  .ant-list-item-meta {
    display: flex;
    flex: 1;
    align-items: center;
    max-width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
  }
  input {
    height: 38px;
    border-radius: 6px;
    margin-right: 15px;
  }
  nz-list-item-meta-avatar {
    img {
      height: 45px;
      border-radius: 60px;
    }
  }
  nz-list-item-meta-title {
    h4 {
      font-size: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      color: #444;
      margin-bottom: 2px;
      img {
        width: 20px;
        margin-left: 10px;
      }
    }
  }
  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }
  nz-list-item-meta-description {
    img {
      width: 18px;
      margin-right: 6px;
    }
  }
  .cdk-virtual-scrollable {
    height: auto !important;
    min-height: 82px;
    contain: initial;
  }

  .ant-select-dropdown {
    border-radius: 10px;
  }

  .ant-select-item {
    align-items: center;
  }
  .cdk-virtual-scroll-spacer {
    height: 200px !important;
  }
}

.creator-filter-dropdown {
  min-width: 130px;

  .ant-select-dropdown {
    margin-top: 10px;
  }

  .ant-select-item {
    .ant-select-item-option-content {
      text-transform: capitalize;

      .img {
        top: 0;
      }
    }
  }

  .cdk-virtual-scroll-viewport {
    height: auto !important;
    contain: unset;
    max-height: 300px !important;
    min-height: 120px;
  }

  .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-select-dropdown {
    padding: 0;
    border-radius: 15px;
    padding-top: 10px;

    .ant-select-item {
      .img {
        display: flex;
        padding: 2px 4px;
        img {
          width: 22px;
          margin-right: 10px;
        }
      }

      .nam {
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .main-stats .card-box .box .others {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    .add {
      margin-bottom: 5px;
    }
  }
  .transactions .filter .ant-col,
  .main-content .filter .ant-col,
  .main-stats .filter .ant-col,
  .main-stats .filters .ant-col {
    margin-bottom: 5px;
  }
}
