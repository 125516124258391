@import "mixin.scss";
@import "variable.scss";
@import "./admin/admin.scss";
@import "./user/user.scss";
@import "./pool/pool.scss";
@import "./dashboard/dashboard.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
}

.btn-primary {
  background-color: #6631f7;
  color: #ffffff;
  border: 1px solid #6631f7;
  border-radius: 8px;

  min-height: 42px;
  padding: 9px 30px;

  .anticon {
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    background-color: #ffffff;
    border: 1px solid #6631f7;
    color: #6631f7;

    svg {
      path {
        stroke: #6631f7;
      }
    }
  }
}

.trans-button {
  background-color: #fff;
  color: #6631f7;
  border: 1px solid #6631f7;
  border-radius: 5px;

  span {
    font-size: 14px;
  }

  &:hover {
    background-color: #6631f7;
    color: #fff;
  }
}

.text-button {
  background-color: transparent;
  color: #6631f7;
  border: none;
  border-radius: 8px;

  span {
    font-size: 14px;
  }

  &:hover {
    background-color: #6631f7;
    color: #fff;
  }
}

img {
  max-width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
}

.ant-card {
  border-radius: 8px;
}

.success-tag,
.failed-tag,
.purple-tag,
.pending-tag {
  border-radius: 30px;
  border: none;
  font-size: 12px;
  text-transform: capitalize;
}

.success-tag {
  background-color: #e8f5e7;
  color: #4fa531;
}

.failed-tag {
  background-color: #fff4f4;
  color: #ed6666;
}

.pending-tag {
  color: #956c0f;
  background-color: #fff2e0;
}

.purple-tag {
  color: #7546f8;
  background-color: #f0eafc;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #6631f7;
    border-color: #6631f7 !important;
  }
}

.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-inner {
      border-color: #6631f7;
    }
  }
}

.ant-layout {
  min-height: 100vh;

  .ant-layout-content {
    margin: 24px 16px;
    min-height: 280px;
  }

  .ant-layout-sider {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    .ant-layout-sider-children {
      display: flex;
      flex-wrap: wrap;
    }

    .ant-menu {
      &.ant-menu-root {
        margin-top: 80px;

        .ant-menu-item {
          padding: 5px 20px !important;
          border-radius: 0;
          margin: 0;
          width: 100%;
          margin-bottom: 5px;

          img {
            margin-right: 8px;
          }

          svg {
            margin-right: 5px;
          }

          &::after {
            display: none;
          }

          &:hover {
            background-color: #f0eafc;
            color: #7546f8;

            img {
              filter: brightness(0) saturate(100%) invert(20%) sepia(57%)
                saturate(6594%) hue-rotate(254deg) brightness(97%) contrast(99%);
            }

            svg {
              path {
                stroke: #7546f8;
              }
            }
          }
        }

        .ant-menu-item-selected {
          background-color: #f0eafc;
          color: #7546f8;

          img {
            filter: brightness(0) saturate(100%) invert(20%) sepia(57%)
              saturate(6594%) hue-rotate(254deg) brightness(97%) contrast(99%);
          }

          svg {
            path {
              stroke: #7546f8;
            }
          }
        }
      }
    }

    .user-option {
      flex: 100%;
      align-self: flex-end;

      .ant-list {
        margin-bottom: 10px;
        border-top: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
      }

      .ant-list-item {
        padding: 10px 20px;
        border: none;
      }

      .ant-spin-container {
        .ant-menu-submenu {
          .ant-avatar {
            height: 28px;
            width: 28px;
            background-color: #946ff9;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 27px;
            margin-right: 10px;
          }

          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 160px;
            overflow: hidden;
            display: inline-block;
            vertical-align: middle;
          }

          .ant-menu-submenu-title,
          .ant-menu-submenu-arrow {
            color: #808080;
          }

          &:hover {
            .ant-menu-submenu-title,
            .ant-menu-submenu-arrow {
              color: #946ff9;
            }
          }
        }

        .ant-list-item {
          a {
            color: #808080;
            text-wrap: nowrap;
            width: 100%;

            .anticon.anticon-down {
              float: right;
              margin-top: 8px;
            }

            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .demo-logo-vertical {
      display: flex;
      align-items: center;
      min-height: 64px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid #f5f5f5;
      padding: 0px 20px;

      .header-trigger {
        cursor: pointer;
      }

      .ant-btn {
        padding: 0;
        height: auto;
        width: auto;
        line-height: 12px;
        margin-top: 7px;
      }

      img {
        width: 87px;
        height: 24px;
        margin-left: 30px;
      }
    }
  }

  .ant-layout-sider + .ant-layout-content {
    padding-left: 260px;
  }

  .ant-layout-sider-collapsed {
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;

    .ant-menu-item {
      span {
        opacity: 0;
        visibility: hidden;
      }
    }

    .user-option {
      .ant-list {
        .ant-list-items {
          .ant-list-item {
            .ant-dropdown-trigger {
              .anticon.anticon-down {
                display: none;
              }
            }
          }

          .ant-menu-submenu {
            .ant-menu-submenu-title {
              width: 60px;
              padding: 0;
              padding-left: 15px;
              text-overflow: clip;

              .ant-menu-submenu-arrow {
                right: 4px;
              }

              .ant-avatar + span {
                opacity: 0;
              }
            }
          }
        }
      }
    }

    .copyright {
      display: none;
    }
  }

  .ant-layout-sider-collapsed + .ant-layout-content {
    padding-left: 60px;
  }
}

.ant-avatar {
  .ant-layout-content {
    background-color: transparent;
    padding: 0;
  }
}

.ant-layout-sider-collapsed {
  overflow: hidden;

  .ant-menu {
    &.ant-menu-inline-collapsed {
      width: 80px !important;
    }
  }

  .copyright {
    text-wrap: nowrap;
  }
}

.copyright {
  color: #808080;
  font-size: 12px;
  padding: 0px 20px;
}

.ant-layout
  .ant-layout-sider
  .user-option
  .ant-spin-container
  .ant-list-item
  a
  span,
.ant-layout
  .ant-layout-sider
  .user-option
  .ant-spin-container
  .ant-list-item
  a
  svg {
  vertical-align: middle;
}

.ant-layout-sider-collapsed
  .user-option
  .ant-spin-container
  .ant-list-item
  a
  svg
  + span,
.ant-layout-sider-collapsed
  .user-option
  .ant-spin-container
  .ant-list-item
  a
  .ant-avatar
  + span {
  display: none;
}

.ant-form-item-label {
  label {
    text-transform: capitalize;
  }
}

.ant-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 43px;

      &:hover {
        border-color: #6631f7;
      }

      .ant-select-selection-item {
        line-height: 40px;
        color: #111;
      }
    }
  }

  .ant-select-selection-search-input {
    height: 41px !important;
    line-height: 40px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 40px !important;
  }
}

.ant-select-focused {
  .ant-select-selector {
    color: #6631f7 !important;
  }
}

.ant-picker {
  height: 43px;

  &:hover {
    border-color: #6631f7;
  }

  .ant-picker-active-bar {
    background-color: #6631f7;
  }
}

.ant-input {
  height: 43px;
  border-radius: 8px !important;
  font-size: 14px;

  &:focus,
  &:hover {
    border-color: #6631f7 !important;
    box-shadow: 0 0 0 2px #6631f72c;
  }
}

.ant-input-wrapper {
  .ant-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    &:focus,
    &:hover {
      border-color: #6631f7 !important;
      box-shadow: 0 0 0 2px #6631f72c;
    }
  }

  .ant-input-group-addon {
    .ant-btn {
      height: 44px;
    }
  }
}

.ant-input-affix-wrapper,
.ant-select .ant-select-selector,
.ant-picker {
  border: 1px solid #e6e6e6;
  border-radius: 8px !important;
}

nz-input-group .ant-input-affix-wrapper {
  border-radius: 8px 0px 0px 8px !important;
}

.ant-select-multiple {
  .ant-select-selection-item {
    line-height: 24px !important;
    border-radius: 30px;
    background-color: #f0eafc;

    .ant-select-selection-item-content {
      font-size: 12px;
      color: #6631f7;
    }
  }
}

.ant-input-affix-wrapper {
  &:hover {
    border-color: #6631f7 !important;
  }

  .ant-input {
    height: 34px;

    &:hover {
      box-shadow: none !important;
    }
  }
}

.ant-input-lg {
  height: 53px;
}

.ant-input-affix-wrapper-lg {
  .ant-input {
    height: 37px;
  }
}

form {
  nz-form-item {
    nz-form-control {
      .ant-select {
        width: 100%;
      }
    }
  }
}

.ant-btn {
  border-radius: 6px;
}

.ant-pagination {
  margin-top: 15px;
  text-align: right;

  .ant-pagination-options {
    .ant-pagination-options-quick-jumper {
      input {
        border-radius: 8px;

        &:hover,
        &:focus {
          border-color: #6631f7;
        }
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 8px;
    }
  }

  .ant-pagination-item {
    border-radius: 8px;

    &:hover {
      border-color: #6631f7;

      a {
        color: #6631f7;
      }
    }
  }

  .ant-pagination-item-active {
    border-color: #6631f7;

    a {
      color: #6631f7;
    }
  }
}

nz-range-picker {
  width: 100%;
}

.purple-button {
  padding: 10px;
  border-radius: 5px;
  height: 44px;
  background-color: #6631f7;
  border: 1px solid transparent !important;

  &:hover,
  &:focus {
    background-color: #fff !important;
    color: #8d5cff !important;
    border: 1px solid #8d5cff !important;

    svg {
      path {
        stroke: #8d5cff;
      }
    }
  }
}

.light-purple {
  background-color: #f0eafc;
  color: #8d5cff;
  border: none;
  height: 44px;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #8d5cff !important;

    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.light-red {
  width: 255px;
  height: 44px;
  font-weight: 500;
  background-color: #ffffff;
  color: #ff6b6b;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #ff6b6b !important;

    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.trans-button {
  background-color: #fff;
  color: #8d5cff;
  border: 1px solid #8d5cff;
  height: 44px;

  &:hover,
  &:focus {
    background-color: #8d5cff !important;
    color: #fff !important;
    border-color: #8d5cff;

    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.danger-button {
  background-color: #ed6666;
  color: #fff;
  border: 1px solid transparent;
  height: 44px;

  &:hover {
    background-color: #fff !important;
    color: #ed6666 !important;
    border: 1px solid #ed6666;

    svg {
      path {
        stroke: #ed6666;
      }
    }
  }

  &:focus {
    background-color: #ed6666;
    color: #fff;
    border: 1px solid #ed6666;
  }
}

.light-danger {
  background-color: #fff4f4;
  border: none;
  height: 44px;

  &:hover,
  &:focus {
    background-color: #ed6666;

    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.gray-button {
  background-color: #ebebeb;
  height: 44px;
  color: #9e9e9e;
  border: 1px solid transparent;

  &:hover {
    background-color: #ebebeb;
    color: #111;
    border: 1px solid transparent;
  }

  &:focus {
    background-color: #ebebeb;
    border: 1px solid transparent;

    svg {
      path {
        stroke: #111;
      }
    }
  }
}

.ant-progress-bg {
  background-color: #6631f7;
}

.ant-progress-inner {
  background-color: #e6e6e6;
}

.ant-modal {
  .ant-modal-header {
    border-radius: 16px 16px 0 0;
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .modal-footer {
    .ant-btn {
      padding: 10px 30px;
      margin-left: 10px;
    }
  }
}

.countryDropdown {
  .ant-select-item {
    .ant-select-item-option-content {
      img {
        vertical-align: middle;
        margin-right: 10px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }
    }
  }
}

.confirmation-popup-card {
  border: none;
  text-align: center;

  .icon {
    height: 140px;
    width: 140px;
    border-radius: 50%;
  }

  h4 {
    font-size: 20px;
    margin-top: 15px;
  }
}

.add-list {
  width: 100%;

  .ant-list-item {
    border: 1px solid #e6e6e6 !important;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    align-items: flex-start;
  }

  .role-detail {
    width: 100%;

    .role-header {
      margin-bottom: 10px;

      .ant-select {
        width: 100%;

        .ant-select-selector {
          .ant-select-selection-placeholder {
            line-height: 40px;
          }
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .ant-checkbox-group {
      .ant-checkbox-wrapper {
        flex: 20%;
        max-width: 20%;
        margin-left: 0;
        margin-bottom: 5px;
      }
    }
  }
}

.auth {
  .authbanner {
    height: 100vh;
    background-image: url("../images/login.png");
    background-size: cover;
    background-position: center;
  }

  .loginForm {
    max-width: 500px;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    padding: 50px 15px;

    .ant-page-header {
      padding-left: 0;

      .ant-page-header-heading-left {
        flex-direction: column;
        align-items: flex-start;

        .ant-page-header-heading-sub-title {
          white-space: normal;
        }
      }
    }
  }

  .logo {
    flex: 100%;
  }

  .loginForm .form {
    align-self: flex-start;
  }

  .loginForm .form h1 {
    font-size: 32px;
  }

  .loginForm .form h1 span {
    color: #ff2e60;
  }

  .loginForm .form p {
    margin-bottom: 30px;
    line-height: 1.5;
    margin-top: 0;
  }

  .loginForm .form .purple-button {
    width: 100%;
    margin-top: 15px;
  }
}

.des-text {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .ant-table-tbody {
    tr {
      td {
        padding: 16px 10px;
      }
    }
  }

  .pool {
    .user-content {
      .ant-table-wrapper {
        .table-action {
          .ant-btn {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

nz-collapse.custom-plan {
  border: 1px #d9d9d9 solid;
  border-radius: 6px !important;
  background-color: white;
  padding: 0px !important;

  nz-collapse-panel {
    .ant-collapse-header {
      border: 0px #d9d9d9 solid;
      border-radius: 6px !important;
      background-color: white;
      padding: 10px 15px !important;
    }

    nz-checkbox-wrapper {
      label.ant-checkbox-wrapper {
        width: 100%;
        padding: 5px;
        margin-bottom: 5px;

        span.ant-checkbox {
          position: absolute;
          right: 0px;
        }
      }
    }
  }
}

.custom-plan {
  nz-form-item {
    nz-form-control {
      nz-input-group {
        span.ant-input-prefix {
          color: #7342f8;
          font-weight: 500;
          font-size: 20px;
        }
      }
    }
  }
}

nz-collapse.custom-plan.show {
  .ant-collapse-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;

    .ant-collapse-arrow {
      top: 20px;
    }

    .ant-collapse-extra {
      margin-left: 0px;

      .selected-feature {
        span {
          border-radius: 30px;
          background-color: #eee8ff;
          color: #6631f7;
          border: none;
          padding: 1px 15px;
          margin-right: 5px;
          display: inline-block;
          margin-top: 5px;
        }
      }
    }
  }

  .feature-detail > * {
    width: 80%;
  }

  .feature-detail {
    width: 100%;
    margin-bottom: 5px;

    nz-progress {
      margin: 0px 30px 0px 15px;
    }

    input {
      width: 70px;
      text-align: center;
      height: 32px;
      padding: 2px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .web-only {
    display: none !important;
  }
}
