.admin-dropdown {
  border-radius: 8px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  .ant-menu-item-group-title {
    display: none;
  }

  .ant-menu-item-group-list {
    .ant-menu-item {
      padding: 0px 15px;
      height: auto;
      color: #969696;
      svg {
        vertical-align: middle;
        margin-right: 10px;
      }
      &:hover {
        color: #7546f8;
        svg {
          path {
            fill: #7546f8;
          }
        }
      }
    }
    .ant-menu-item-selected {
      background-color: transparent;
      color: #969696;
    }
    .ant-menu-item.active {
      background-color: transparent;
      color: #7546f8;
      svg {
        path {
          fill: #7546f8;
        }
      }
    }
  }
}
.profile-pic {
  .-label {
    position: absolute;
    background-color: #fff;
    img {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
.upload-img-card {
  border: none;
  .ant-card-head {
    padding: 0;
    border-bottom: none;
  }
  .ant-card-body {
    padding: 0;
    .file {
      position: absolute;
      opacity: 0;
      height: 80px;
      width: 80px;
      cursor: pointer;
    }
    .img-upload-icon {
      height: 80px;

      width: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      text-align: center;
      border: 1px solid #e0d6fd;
      .icon {
        align-self: center;
      }
    }
  }
}
.permission {
  text-align: center;
  height: 95vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  .ant-result-title {
    font-size: 28px;
    color: #333333;
  }
  p {
    font-size: 20px;
    color: #666666;
    max-width: 444px;
    margin: 0 auto;
    a {
      color: #7546f8;
    }
  }
}

.role-del {
  color: #ed6666;
  padding: 0 !important;
}

.edit-role-card {
  .ant-btn {
    padding: 10px 30px;
    margin-left: 10px;
  }
}

.logs {
  .filter {
    padding: 15px;
    border-bottom: 1px solid #e6e6e6;
    .ant-picker {
      width: 100%;
    }
    .ant-input-wrapper {
      .ant-input-group-addon {
        .ant-btn {
          height: 44px;
          width: 44px;
        }
      }
    }
  }
  .ant-card-body {
    padding: 0;
  }
  .logs-list {
    padding-left: 0;
    padding: 15px;
    li {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      .list-header {
        cursor: pointer;
      }
      nz-table {
        width: 100%;
      }
      .date-time {
        display: flex;
        flex-wrap: wrap;
        min-width: 276px;
        padding-right: 30px;
        margin-right: 60px;
        border-right: 1px solid #e6e6e6;
        position: relative;
        color: #808080;
        font-size: 12px;
        img {
          vertical-align: sub;
        }
        &::after {
          content: "";
          position: absolute;
          height: 12px;
          width: 12px;
          background-color: #f0eafc;
          border-radius: 50%;
          right: 0;
          margin-right: -7px;
        }
        .date {
          min-width: 140px;
          padding-right: 10px;
        }
      }
      .status {
        p {
          img {
            margin-right: 10px;
          }
          span + span {
            color: #7546f8;
          }
        }
      }
      &:hover {
        .date-time {
          &::after {
            background-color: #7546f8;
          }
        }
      }
      &:last-child {
        .date-time {
          border-right: none;
        }
      }
    }
  }
}
